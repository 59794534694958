import { Mode } from '@/src/containers/Pricing/types';
import { IUser } from '@/types/user';

export type SubscriptionType = IUser['subscription'];

export type ISubscription = {
  subscription_type: UpgradePlans;
  next_payment?: string;
  cancel_on?: string;
  plan_type?: Mode;
  member_since?: string;
  next_billing_amount?: string;
  is_paused?: boolean;
  pause_ended_date?: string;
  paused_date?: string;
  latest_pause_date?: string;
  downgrade_plan?: string;
  can_pause?: boolean;
};

export enum UpgradePlans {
  none = 'none',
  lite = 'lite',
  basic = 'basic',
  premium = 'premium',
  platinum = 'platinum',
  diamond = 'diamond',
  newsletter = 'newsletter',
}
