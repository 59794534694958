import React, { useState } from 'react';
import { FacebookAddressAutocomplete } from '@/components/facebook-address-autocomplete';
import { Button } from '@/src/shared/components/Button';
import styles from './LocationInput.module.scss';
import type { GeoLocationResult } from '@/src/containers/Landing/utils/geoVideo/types';
import type { IIPLocationDetails } from '@/types/misc';
import type { IVideo } from '@/types/video';
import dynamic from 'next/dynamic';
import { useEventProvider } from '@/helpers/event-context';
import { useHome } from '../../HomeProvider';

const RegistrationPopup = dynamic(
  () => import('@/src/containers/RegistrationPopup')
);

interface Props {
  regions: GeoLocationResult['regions'];
  detectedRegion: GeoLocationResult['detectedRegion'];
  defaultRegionName: GeoLocationResult['default_region_name'];
  ipLocationDetails?: IIPLocationDetails;
  video: IVideo;
}

export function LocationInput({
  ipLocationDetails,
  regions,
  detectedRegion,
  defaultRegionName,
  video,
}: Props) {
  const {
    isRegistrationPopupOpen,
    openRegistrationPopup,
    closeRegistrationPopup,
  } = useHome();
  const [location, setLocation] = useState({
    regionName: ipLocationDetails?.region ?? '',
    cityName: ipLocationDetails?.city ?? '',
    regionKey: null,
    countryCode: (ipLocationDetails?.country ?? '').toLowerCase(),
  });
  const { sendEvent, getPageName } = useEventProvider();

  const getLocation = (): string => {
    if (!location.regionName || !location.cityName) return '';
    return `${location.cityName}, ${location.regionName}`;
  };

  const handleCtaClick = () => {
    sendEvent('get_video_clicked', null, { section: 'hero_section' });
    openRegistrationPopup();
  };

  return (
    <>
      <div className={styles.inputWrapper}>
        <FacebookAddressAutocomplete
          onSelect={(cityName, regionName, regionKey, country_code) => {
            setLocation({
              regionName,
              cityName,
              regionKey,
              countryCode: (country_code ?? '').toLowerCase(),
            });
          }}
          placeholder="Type your city name"
          defaultValue={getLocation()}
          className={styles.locationInput}
          classes={{ root: styles.inputRoot }}
          dropdownClassName={styles.contentFactoryLocationInputDropdown}
          handleSubmit={handleCtaClick}
        />

        <Button
          fullWidth
          color="primary"
          className={styles.cta}
          onClick={handleCtaClick}
          size="xl"
        >
          Get Your Video
        </Button>
      </div>
      {isRegistrationPopupOpen && (
        <RegistrationPopup
          key="register-popup"
          source={getPageName()}
          open
          title="Get Your Free Video"
          onClose={() => closeRegistrationPopup()}
          regions={regions}
          defaultRegion={defaultRegionName || detectedRegion}
          phoneCountryCode={location?.countryCode}
          ctaLabel="Get Free Videos"
          videoId={video?.id}
          section="city_form"
          regionInputType="location-selector"
          defaultLocation={{
            userRegionName: location?.regionName ?? ipLocationDetails.region,
            userCityName: location?.cityName ?? ipLocationDetails.city,
            userRegionFBKey: null,
          }}
        />
      )}
    </>
  );
}
