export const CalendlyLinks = {
  freeOnBoardingSession:
    'https://calendly.com/d/dx3-t75-hhh/free-onboarding-session',
  freeOnboardingActionPlanSession:
    'https://calendly.com/d/dx3-t75-hhh/free-onboarding-session?a2=I%27m%20coming%20to%20claim_ads.&utm_source=Inbound&utm_medium=Webinar&utm_campaign=Webinar&month=2024-06',
  designRVFeedback: 'https://calendly.com/design-rv/feedback',
  roomvuCS15min: 'https://calendly.com/d/3b9-bwb-657/support-session',
  roomvuSupportPlatinumDiamond:
    'https://calendly.com/roomvu-support/platinum-diamond',
  weeklyCeoDemo: 'https://calendly.com/samme/ceo-demo?back=1&month=2024-05',
  instagramAuditReviewSession:
    'https://calendly.com/d/cpxs-shv-mjw/instagram-audit-review-session',
  brokerage30min: 'https://calendly.com/roomvubizdev/brokerage30min',
  smartAdsSupport: 'https://calendly.com/smartads-roomvu/smart-ads-support',
};

export const SupportCalendlyLinks = {
  proPremium: 'https://calendly.com/d/3b9-bwb-657/support-session',
  platinumDiamond: 'https://calendly.com/roomvu-support/platinum-diamond',
};

export const CeoCalendlyLinks = {
  free: 'https://calendly.com/samme/ceo-demo?back=1&month=2024-05',
  paid: 'https://calendly.com/samme/roomvu-success-sessions',
  socialMediaTraining: 'https://calendly.com/samme/social-media-training',
};
