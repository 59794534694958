import type { IUser } from '@/types/user';
import { IUserVideoNewsPlayerSample } from '@/types/user';
import { IProfileInfo } from '@/types/profile';

export type UserTypeIdName =
  | 'broker'
  | 'mortgage'
  | 'commercial_agent'
  | 'team_lead'
  | 'individual_agent'
  | 'insurance'
  | 'finance';

export const getUserTypeIdName = (
  userTypeId: IUser['user_type_id']
): UserTypeIdName => {
  switch (userTypeId) {
    case 8:
    case 5:
      return 'broker';
    case 6:
      return 'mortgage';
    case 7:
      return 'commercial_agent';
    case 10:
      return 'team_lead';
    case 11:
      return 'insurance';
    case 12:
      return 'finance';
    case 2:
    default:
      return 'individual_agent';
  }
};

export const getUserTypeId = (userTypeIdName: UserTypeIdName) => {
  switch (userTypeIdName) {
    case 'broker':
      return 8;
    case 'mortgage':
      return 6;
    case 'commercial_agent':
      return 7;
    case 'team_lead':
      return 10;
    case 'insurance':
      return 11;
    case 'finance':
      return 12;
    case 'individual_agent':
    default:
      return 2;
  }
};

export function isUSAUser(user: IUser) {
  return user?.country === 'United States';
}

export function isCanadianUser(user: IUser) {
  return user?.country === 'Canada';
}

export function getUserPremiumIntroRandomizer(
  user: IUser | IUserVideoNewsPlayerSample | IProfileInfo | Partial<IUser>,
  id: number
) {
  // use formatted phone
  user = { ...user, phone: user?.formatted_phone || user?.phone };

  // handle premium intro randomizer
  if (
    user?.videoOptions?.use_randomize_premium_branding &&
    user?.videoOptions?.randomize_premium_branding_templates.length > 0
  ) {
    const index =
      id % user?.videoOptions?.randomize_premium_branding_templates.length;
    const randomPremiumBrandingId =
      user?.videoOptions?.randomize_premium_branding_templates[index];
    const randomPremiumBranding = user?.premium_intros.find(
      (intro) => intro.template?.id == randomPremiumBrandingId
    );

    if (randomPremiumBranding) {
      return { ...user, premium_intro: randomPremiumBranding };
    }
  }

  return user;
}
