import { useSelector } from 'react-redux';
import {
  selectIsBasicPlanHidden,
  selectIsFreeUser,
  selectUserInfo,
} from '@/redux/user/selectors';
import type { IUser } from '@/types/user';
import type { IVideo } from '@/types/video';
import { empty } from '@/core/helpers';
import { UpgradePlans } from '@/types/subscription';

export type VideoAction =
  | 'download'
  | 'youtube'
  | 'instagram'
  | 'instagram_business'
  | 'tiktok'
  | 'facebook'
  | 'facebook_2'
  | 'twitter'
  | 'linkedin'
  | 'wordpress'
  | 'facebook-profile'
  | 'all';

export const isBasicOrLowerUser = (user: IUser) => {
  return (
    user?.subscription !== 'premium' &&
    user?.subscription !== 'platinum' &&
    user?.subscription !== 'diamond'
  );
};

export const isPlatinumOrHigher = (user: IUser) => {
  return user?.subscription === 'platinum' || user?.subscription === 'diamond';
};

export const isPremiumOrLowerUser = (user: IUser) => {
  return user?.subscription !== 'platinum' && user?.subscription !== 'diamond';
};

export function useFeature() {
  const user = useSelector(selectUserInfo);
  const isFree = useSelector(selectIsFreeUser);
  const isBasicPlanHidden = useSelector(selectIsBasicPlanHidden);

  const needsUpgrade = (platform: string): UpgradePlans =>
    doesNeedUpgrade(platform, isFree, user, isBasicPlanHidden);

  const videoNeedsUpgrade = (
    video: IVideo,
    action: VideoAction
  ): UpgradePlans =>
    videoDoesNeedUpgrade(video, user, action, isBasicPlanHidden);

  return {
    needsUpgrade,
    videoNeedsUpgrade,
    ABTest_noDownload: showDownload(isFree, user),
  };
}

function doesFacebookNeedsUpgrade(
  isFree: boolean,
  user: IUser,
  isBasicPlanHidden: boolean
): UpgradePlans {
  // TODO: We should use a dynamic thing instead of brokerage id
  // Brokerage has a trial subscription plan for its users
  if (user?.brokerage_id == 26 && isFree) {
    return UpgradePlans.basic;
  }

  // For users in a brokerage with active brokerage subscription or an organization
  let result = freeUsersSocialForBrokerageOrOrganization(
    user,
    isFree,
    'facebook'
  );
  if (result !== false) return result;

  if (
    isFree &&
    empty(user?.userAdsInfo?.hasActiveAd) &&
    user?.user_type_id != 9
  ) {
    return isBasicPlanHidden ? UpgradePlans.premium : UpgradePlans.basic;
  }

  return UpgradePlans.none;
}

function doesFacebook2NeedsUpgrade(isFree: boolean, user: IUser): UpgradePlans {
  // TODO: We should use a dynamic thing instead of brokerage id
  // Brokerage has a trial subscription plan for its users
  if (user?.brokerage_id == 26 && isFree) {
    return UpgradePlans.basic;
  }

  if (isPremiumOrLowerUser(user)) {
    return UpgradePlans.platinum;
  }
  return UpgradePlans.none;
}

function doesFacebookProfileNeedsUpgrade(
  isFree: boolean,
  user: IUser
): UpgradePlans {
  // TODO: We should use a dynamic thing instead of brokerage id
  // Brokerage has a trial subscription plan for its users
  if (user?.brokerage_id == 26 && isFree) {
    return UpgradePlans.basic;
  }

  if (isPremiumOrLowerUser(user)) {
    return UpgradePlans.platinum;
  }
  return UpgradePlans.none;
}

function doesWordpressNeedsUpgrade(isFree: boolean, user: IUser): UpgradePlans {
  if (isBasicOrLowerUser(user)) {
    return UpgradePlans.premium;
  }
  return UpgradePlans.none;
}

function doesGoogleMyBusinessNeedsUpgrade(user: IUser): UpgradePlans {
  if (!isPlatinumOrHigher(user)) {
    return UpgradePlans.platinum;
  }
  return UpgradePlans.none;
}

function doesTwitterNeedsUpgrade(isFree: boolean, user: IUser): UpgradePlans {
  // TODO: We should use a dynamic thing instead of brokerage id
  // Brokerage has a trial subscription plan for its users
  if (user?.brokerage_id == 26 && isFree) {
    return UpgradePlans.premium;
  }

  // For users in a brokerage with active brokerage subscription or an organization
  let result = freeUsersSocialForBrokerageOrOrganization(
    user,
    isFree,
    'twitter'
  );
  if (result !== false) return result;

  if (isBasicOrLowerUser(user)) return UpgradePlans.premium;

  return UpgradePlans.none;
}

function doesInstagramNeedsUpgrade(
  isFree: boolean,
  user: IUser,
  isBasicPlanHidden: boolean
): UpgradePlans {
  // TODO: We should use a dynamic thing instead of brokerage id
  // Brokerage has a trial subscription plan for its users
  if (user?.brokerage_id == 26 && isFree) {
    return UpgradePlans.basic;
  }

  if (isFree && isBasicPlanHidden) {
    return UpgradePlans.premium;
  }
  if (isFree) {
    return UpgradePlans.basic;
  }
  return UpgradePlans.none;
}

function doesLinkedinNeedsUpgrade(
  isFree: boolean,
  user: IUser,
  isBasicPlanHidden: boolean
): UpgradePlans {
  // TODO: We should use a dynamic thing instead of brokerage id
  // Brokerage has a trial subscription plan for its users
  if (user?.brokerage_id == 26 && isFree) {
    return UpgradePlans.basic;
  }

  // For users in a brokerage with active brokerage subscription or an organization
  let result = freeUsersSocialForBrokerageOrOrganization(
    user,
    isFree,
    'linkedin'
  );
  if (result !== false) return result;

  if (isFree)
    return isBasicPlanHidden ? UpgradePlans.premium : UpgradePlans.basic;

  return UpgradePlans.none;
}

function doesTickTokNeedsUpgrade(isFree: boolean, user: IUser): UpgradePlans {
  // TODO: We should use a dynamic thing instead of brokerage id
  // Brokerage has a trial subscription plan for its users
  if (user?.brokerage_id == 26 && isFree) {
    return UpgradePlans.basic;
  }

  if (isBasicOrLowerUser(user)) {
    return UpgradePlans.premium;
  }
  return UpgradePlans.none;
}

function doesYoutubeNeedsUpgrade(isFree: boolean, user: IUser): UpgradePlans {
  // TODO: We should use a dynamic thing instead of brokerage id
  // Brokerage has a trial subscription plan for its users
  if (user?.brokerage_id == 26 && isFree) {
    return UpgradePlans.basic;
  }

  if (isBasicOrLowerUser(user)) {
    return UpgradePlans.premium;
  }
  return UpgradePlans.none;
}

function showDownload(isFree: boolean, user: IUser) {
  return isFree && user?.cohort_type == 5;
}

export const videoDoesNeedUpgrade = (
  video: IVideo,
  user: IUser,
  action: VideoAction,
  isBasicPlanHidden: boolean
): UpgradePlans => {
  const needDownloadAccess = [
    'download',
    'youtube',
    'instagram',
    'instagram_business',
    'tiktok',
  ];

  const isFree = user.subscription === 'none';

  let connectBadge = undefined;
  let shareBadge = undefined;
  let resultBadge = undefined;

  if (needDownloadAccess.indexOf(action) > -1) {
    shareBadge = video.minimum_access_download;
  } else {
    shareBadge = video.minimum_access_share;
  }

  connectBadge = doesNeedUpgrade(
    action,
    user.subscription == 'none',
    user,
    isBasicPlanHidden
  );

  if (shareBadge === 'premium' || connectBadge == UpgradePlans.premium) {
    resultBadge = UpgradePlans.premium;
  } else if (shareBadge === 'basic' || connectBadge == UpgradePlans.basic) {
    if (isFree && isBasicPlanHidden) resultBadge = UpgradePlans.premium;
    else resultBadge = UpgradePlans.basic;
  }

  switch (resultBadge) {
    case UpgradePlans.premium:
      if (['none', 'basic'].indexOf(user.subscription) > -1)
        return UpgradePlans.premium;

    case UpgradePlans.basic:
      if (['none'].indexOf(user.subscription) > -1) return UpgradePlans.basic;
  }

  return UpgradePlans.none;
};

export const doesNeedUpgrade = (
  platform: string,
  isFree: boolean,
  user: IUser,
  isBasicPlanHidden: boolean
): UpgradePlans => {
  let type = UpgradePlans.none;
  switch (platform) {
    case 'facebook':
      type = doesFacebookNeedsUpgrade(isFree, user, isBasicPlanHidden);
      break;
    case 'facebook_2':
      type = doesFacebook2NeedsUpgrade(isFree, user);
      break;
    case 'twitter':
      type = doesTwitterNeedsUpgrade(isFree, user);
      break;
    case 'linkedin':
      type = doesLinkedinNeedsUpgrade(isFree, user, isBasicPlanHidden);
      break;
    case 'instagram':
    case 'instagram_business':
      type = doesInstagramNeedsUpgrade(isFree, user, isBasicPlanHidden);
      break;
    case 'tiktok':
      type = doesTickTokNeedsUpgrade(isFree, user);
      break;
    case 'youtube':
      type = doesYoutubeNeedsUpgrade(isFree, user);
      break;
    case 'facebook-profile':
      type = doesFacebookProfileNeedsUpgrade(isFree, user);
      break;
    case 'wordpress':
      type = doesWordpressNeedsUpgrade(isFree, user);
      break;
    case 'google_my_business':
      type = doesGoogleMyBusinessNeedsUpgrade(user);
      break;
  }

  return type;
};

export function getUpgradePlanName(plan: UpgradePlans | IUser['subscription']) {
  switch (plan) {
    case UpgradePlans.lite:
      return 'Lite';
    case UpgradePlans.basic:
      return 'Basic';
    case UpgradePlans.premium:
      return 'Premium';
    case UpgradePlans.platinum:
      return 'Platinum';
    case UpgradePlans.diamond:
      return 'Diamond';
    case UpgradePlans.newsletter:
      return 'Constant Contact';
    default:
    case UpgradePlans.none:
      return null;
  }
}

export function getUpgradePlanNameNew(
  plan: UpgradePlans | IUser['subscription']
) {
  switch (plan) {
    case UpgradePlans.lite:
      return 'Lite';
    case UpgradePlans.basic:
      return 'Social Starter';
    case UpgradePlans.premium:
      return 'Omni-Presence';
    case UpgradePlans.platinum:
      return 'Brand Builder';
    case UpgradePlans.diamond:
      return 'Agency Package';
    case UpgradePlans.newsletter:
      return 'Constant Contact';
    default:
    case UpgradePlans.none:
      return null;
  }
}

export function getUpgradePlanObject(
  plan: UpgradePlans | IUser['subscription']
) {
  return { key: plan, value: getUpgradePlanName(plan) } as {
    key: UpgradePlans;
    value: string;
  };
}

/**
 * A brokerage or an organization can decide which socials their users can connect
 * as a free social
 */
function freeUsersSocialForBrokerageOrOrganization(
  user: IUser,
  isFree: boolean,
  social: 'linkedin' | 'twitter' | 'facebook'
): UpgradePlans.none | false {
  if (
    isFree &&
    user?.brokerage_details?.brokerage_has_subscription &&
    user?.brokerage_details?.free_users_socials?.length > 0
  )
    return checkFreeUsersSocialForBrokerageOrOrganization(
      user,
      social,
      'brokerage_details'
    );

  if (isFree && user?.organization_details?.free_users_socials?.length > 0)
    return checkFreeUsersSocialForBrokerageOrOrganization(
      user,
      social,
      'organization_details'
    );

  return false;
}

/**
 * A brokerage or an organization can decide which socials their users can connect
 * as a free social
 */
function checkFreeUsersSocialForBrokerageOrOrganization(
  user: IUser,
  social: 'linkedin' | 'twitter' | 'facebook',
  type: 'brokerage_details' | 'organization_details'
): UpgradePlans.none | false {
  if (!user?.[type]?.free_users_socials?.includes(social)) {
    return false;
  }

  // We need to check if user already connected a social from the list
  // They can connect just one of them
  for (let s in user?.[type]?.free_users_socials) {
    s = user?.[type]?.free_users_socials[s];
    if (s != social && user?.user_accounts[s]?.enabled) {
      return false;
    }
  }

  return UpgradePlans.none;
}
