export function IntercomHideStyles() {
  return (
    <style jsx global>{`
      .intercom-container,
      .intercom-lightweight-app-launcher,
      .intercom-app :nth-child(2) {
        display: none !important;
        visibility: hidden !important;
        width: 0 !important;
        height: 0 !important;
      }
    `}</style>
  );
}
