import config from '../config';
import { useEventProvider } from './event-context';

export function usePageUrl() {
  const { getPageName } = useEventProvider();

  const loginPageUrl = (): string => {
    return config.auth.loginUrl + `?s=${getPageName()}`;
  };

  const registerPageUrl = (): string => {
    let baseUrl = config.websiteBaseUrl + `/register?s=${getPageName()}`;

    if (getPageName()?.includes('mortgage-brokers')) {
      baseUrl += '&user_type=6';
    } else if (getPageName()?.includes('brokerages')) {
      baseUrl += '&user_type=8';
    } else if (getPageName()?.includes('insurance')) {
      baseUrl += `&user_type=11`;
    } else if (getPageName()?.includes('financial-advisor')) {
      baseUrl += '&user_type=12';
    }

    return baseUrl;
  };

  return { loginPageUrl, registerPageUrl };
}
