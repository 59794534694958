export function shuffleArray<Type>(array: Type[]): Type[] {
  const result = [...array];
  let currentIndex = result.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [result[currentIndex], result[randomIndex]] = [
      result[randomIndex],
      result[currentIndex],
    ];
  }

  return result;
}

export function isEmptyArray(value: unknown[] | null | undefined): boolean {
  if (value == null) return true;
  return Array.isArray(value) && value.length < 1;
}

export const removeValueFromArray = <T>(arr: T[], value: T): T[] => {
  return arr.filter((item) => item !== value);
};
